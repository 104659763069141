module controllers {
  export module master {
    interface IRequirementGroupUpdateCtrlScope extends ng.IScope {}

    interface IRequirementGroupUpdateCtrlScopeParams
      extends ng.ui.IStateParamsService {
      requirementGroupId: number;
    }

    export class requirementGroupUpdateCtrl {
      static $inject = [
        "$scope",
        "$rootScope",
        "generalService",
        "$q",
        "bsLoadingOverlayService",
        "$timeout",
        "$uibModal",
        "requirementGroupService",
        "entityService",
        "customerService",
        "$state",
        "$stateParams",
        "uiGridConstants",
        "$transitions",
        'classificationValueService',
        "documentTypeService",
        'customAttributeService',
        'reportService'
      ];

      selectedTab: number = 0;
      requirementGroupId: number = 0;
      requirementGroup: interfaces.master.IRequirementGroupViewModel;
      filterTypeList: ng.resource.IResourceArray<interfaces.master.IFilterType>;

      breadCrumbDesc: string;

      IsLoading: boolean = false;
      requirementGroupHook: any;

      gridCustomerApi: uiGrid.IGridApi;

      requirementGroupFilterListFiltered : Array<interfaces.master.IRequirementGroupFilter>;
      requirementGroupDetailListFiltered : Array<interfaces.master.IRequirementGroupDetailViewModel>;

      searchFilterText:string;
      searchDetailText:string;

      constructor(
        private $scope: IRequirementGroupUpdateCtrlScope,
        private $rootScope: interfaces.applicationcore.IRootScope,
        public generalService: interfaces.applicationcore.IGeneralService,
        private $q: ng.IQService,
        private bsLoadingOverlayService,
        private $timeout: ng.ITimeoutService,
        private $uibModal: ng.ui.bootstrap.IModalService,
        private requirementGroupService: interfaces.master.IRequirementGroupService,
        private entityService: interfaces.applicationcore.IEntityService,
        private customerService: interfaces.master.ICustomerService,
        private $state: ng.ui.IStateService,
        private $stateParams: IRequirementGroupUpdateCtrlScopeParams,
        private uiGridConstants: uiGrid.IUiGridConstants,
        private $transitions: ng.ui.core.ITransition,
        private classificationValueService: interfaces.applicationcore.IClassificationValueService,
        private documentTypeService: interfaces.documentManagement.IDocumentTypeService,
        private customAttributeService: interfaces.master.ICustomAttributeService,
        private reportService: interfaces.reporting.IReportService
      ) {
        if ($stateParams.requirementGroupId == null) {
          return;
        }
        this.requirementGroupId = $stateParams.requirementGroupId;

        this.requirementGroupHook = $transitions.onSuccess(
          {
            to: "auth.MasterData.RequirementGroup.Update",
            from: "auth.MasterData.RequirementGroup.Update.**",
          },
          () => {
            return this.loadRequirementGroup();
          }
        );

        this.loadFilterTypes();
        this.loadRequirementGroup();
  
      }

      loadFilterTypes(){
        this.requirementGroupService.getFilterTypes().query((data: ng.resource.IResourceArray<interfaces.master.IFilterType>) =>{
            this.filterTypeList = data;
        });
      }

      loadDocumentTypes() {
        return this.documentTypeService.GetDocumentTypesForDropdownList().query({ ownerEntityId: this.requirementGroup.Entity.Id},
            () => {
            }, (failureData) => {
            }).$promise;
        }

        loadReports() {
            return this.requirementGroupService.getReportsDropDown(this.requirementGroup.Entity.Id).query(
                () => {
                }, (failureData) => {
                }).$promise;
             }

        ddResult:interfaces.applicationcore.IDropdownModel[]=[];

        loadCustomAttributes(){
            this.ddResult = [];
            var categoryKeyId : number;
            switch(this.requirementGroup.TransactionType.Id) { 
                case 595: { //Import Consignment
                    categoryKeyId = 793;
                   break; 
                } 
                case 596: { //Export Consignment
                    categoryKeyId = 784;
                   break; 
                } 
                case 597: {  //Purchase Order
                    categoryKeyId = 796;
                   break; 
                }   
                case 821: { //Sales Order
                    categoryKeyId = 784;
                    break; 
                   break; 
                }     
                case 822: {  //Manifest
                    categoryKeyId = 785;
                   break; 
                }
                case 826: { //Delivery Instruction
                    categoryKeyId = 825;
                }                                                                                                                                        
                default: { 
                   
                   break; 
                } 
             } 
             var defer = this.$q.defer();

             if (this.requirementGroup.TransactionType.Id===595 || this.requirementGroup.TransactionType.Id === 596){
                return this.customAttributeService.GetDropDownListByCategories().query({categoryId:categoryKeyId,categoryId2:555 ,ownerEntityId: this.requirementGroup.Entity.Id},(data) =>{

                }).$promise;
             }
             else
             return  this.customAttributeService.GetDropDownListByCategory().query({categoryId:categoryKeyId, ownerEntityId: this.requirementGroup.Entity.Id},(data) => {                
                }         
                , (errorResponse) => {
                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
            }).$promise;


        }

      addFilter(){
        
          var filter = {
              RequirementGroupId: this.requirementGroup.Id,
              DateFrom: moment.utc(),
              Type: "",
              SelectedFilter: null,
              Exclusion: false
        }
        this.requirementGroup.RequirementGroupFilterList.splice(0,0,filter);
        this.requirementGroupFilterListFiltered = this.requirementGroup.RequirementGroupFilterList;
      }

        addDetail() {
           //var dateType: interfaces.applicationcore.IDropdownModel = { Id: 819, Code: "", Description: "None", Display: "None", Selected: true }

            var detail: interfaces.master.IRequirementGroupDetailViewModel = { Id: 0, DateFrom: moment.utc(), NoOfDays: 0, CreateStampId:0,CreateStampFullName:"",CreateStampDate:null,UpdateStampId:0,UpdateStampFullName:"",UpdateStampDate:null,IsActive:true};
            this.requirementGroup.RequirementGroupDetailList.splice(0,0,detail);
            this.requirementGroupDetailListFiltered = this.requirementGroup.RequirementGroupDetailList;
      }

      deleteDetail(index:number){
        var detail = this.requirementGroup.RequirementGroupDetailList[index];
        if (detail.Id === 0){
            this.requirementGroup.RequirementGroupDetailList.splice(index, 1);
        }
        else{
            detail.IsActive = false;
        }
        this.requirementGroupDetailListFiltered = this.requirementGroup.RequirementGroupDetailList;
      }

      deleteFilter(index: number){
        this.generalService.displayConfirmationBox("Confirm Remove", "Are you sure you want to delete the Filter?").then((result) => {
            this.requirementGroup.RequirementGroupFilterList.splice(index, 1);
            this.requirementGroupFilterListFiltered = this.requirementGroup.RequirementGroupFilterList;
            }
        );
      }

      filterTypeChanged(filter:interfaces.master.IRequirementGroupFilter){
        filter.SelectedFilter = null;
      }

      loadFilterData(searchText:string,filter:interfaces.master.IRequirementGroupFilter){
        return this.requirementGroupService.getFilterDropdownList(this.requirementGroup.TransactionType.Id, this.requirementGroup.Entity.Id, filter.Type,searchText).query(
            () => {
            }, (failureData) => {
            }).$promise;
      }

      searchFilterChanged(){

        this.requirementGroupFilterListFiltered = [];
        for (var i = 0; i < this.requirementGroup.RequirementGroupFilterList.length; i++){    
           
            if (JSON.stringify(this.requirementGroup.RequirementGroupFilterList[i]).toLocaleLowerCase().includes(this.replaceAll(this.searchFilterText.toLocaleLowerCase(),'/','-')))
            {
                this.requirementGroupFilterListFiltered.push(this.requirementGroup.RequirementGroupFilterList[i]);
            }
        }      

      }

      searchDetailChanged(){

        this.requirementGroupDetailListFiltered = [];
        for (var i = 0; i < this.requirementGroup.RequirementGroupDetailList.length; i++){    
           
            if (JSON.stringify(this.requirementGroup.RequirementGroupDetailList[i]).toLocaleLowerCase().includes(this.replaceAll(this.searchDetailText.toLocaleLowerCase(),'/','-')))
            {
                this.requirementGroupDetailListFiltered.push(this.requirementGroup.RequirementGroupDetailList[i]);
            }
        }      

      }      

      replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
      }

      loadRequirementGroup() {
        this.IsLoading = true;
        this.bsLoadingOverlayService.wrap({
            referenceId: 'requirementGroup.update'
        },
            () => {
                return this.requirementGroupService.getDetail(this.requirementGroupId).query((result: interfaces.master.IRequirementGroupViewModel) => {
                    this.IsLoading = false;
                    this.requirementGroup = result;
                    this.requirementGroupFilterListFiltered = this.requirementGroup.RequirementGroupFilterList;
                    this.requirementGroupDetailListFiltered = this.requirementGroup.RequirementGroupDetailList;
 
                    if (this.requirementGroup.Id < 1)
                        this.breadCrumbDesc = 'New Requirement Group';
                    else
                        this.breadCrumbDesc = this.requirementGroup.Code + ' - ' + this.requirementGroup.Name;

                        this.loadCustomAttributes();
                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            });
      }

      loadEntities(searchText: string) {
        return this.entityService.getDropdownList(searchText).query(
            () => {
            }, (failureData) => {
            }).$promise;
         }

         loadTransactionTypes(){
            return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                classificationId: Enum.ClassificationEnum.DocumentTransactionType
            }, (resultList) => {
                   }).$promise;
         }

         loadActionTypes(){
            return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                classificationId: Enum.ClassificationEnum.DocumentActionType
            }, (resultList) => {
                   }).$promise;
         }     

         loadDateTypes(){
            return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                classificationId: Enum.ClassificationEnum.ComplianceDateType
            }, (resultList) => {
                   }).$promise;
         }

         downloadFilterListExcel(){
            this.IsLoading = true;

            this.requirementGroupService.getFilterListExcel(this.requirementGroup.Id).then(() => {
                this.IsLoading = false;
            }, (data) => {
                
            });
         }
         
         
         SaveRequirementGroup(): ng.IPromise<boolean> {
            var deferre = this.$q.defer<boolean>();

            this.requirementGroupService.save().save(this.requirementGroup, (data: interfaces.applicationcore.IMessageHandler) => {
                this.generalService.displayMessageHandler(data);
                this.IsLoading = false;
                this.requirementGroupId = Number(data.ID);                
                if (data.HasErrorMessage)
                    deferre.resolve(false);
                else
                    deferre.resolve(true);
            }, (errorResponse) => {
                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                deferre.resolve(false);
            });

            return deferre.promise;
        };


         Save() {
            this.bsLoadingOverlayService.wrap({
                referenceId: 'requirementGroup.update'
            },
                () => {
                    return this.SaveRequirementGroup().then((data: boolean) => {
                        if (data && this.requirementGroupId > 0) {
                            this.loadRequirementGroup();
                        }
                    });
                });
        }

        SaveClose() {
            this.bsLoadingOverlayService.wrap({
                referenceId: 'requirementGroup.update'
            },
                () => {
                    return this.SaveRequirementGroup().then((data) => {
                        if (data) {
                            this.$state.go("^");
                        } else {

                        }
                    });
                });
        }

        Close() {
            this.$state.go("^");
        }

        reportChange (model:interfaces.master.IRequirementGroupDetailViewModel) {
          
            this.reportService.GetDocumentFromReport(model.Report.Id).get((data: interfaces.applicationcore.IDropdownModel) => {
                if (data.Id>0){
                    model.DocumentType = data;
                }
                else
                {
                    model.DocumentType = undefined;
                }
            }, (errorResponse) => {
                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
            })
        }


        UploadFromExcel(){
            this.$uibModal.open({
                animation: true,
                size: "md",
                template: `
                    <div class="modal-header" style="white-space: nowrap;">
                        <h3 class="modal-title" id="modal-title">Upload from Excel <i class="fa fa-question-circle" uib-tooltip='Use this option to upload Requirement Group Filters using an Excel Spreadsheet. The format of the spreadsheet is the same as the Download to Excel functionality. The upload functionality will replace all filters.' tooltip-placement="bottom"></i></h3>
                    </div>
                    <div bs-loading-overlay bs-loading-overlay-reference-id="excelupload">
                        <form name="excelupload">
                            <div class="gts-padding">
                                <gts-message-display messages="Messages"></gts-message-display>
                                <div class="modal-body" id="modal-body">                                
                                    <div class="row">
                                        <div class="row vertical-align">
                                            <div class="col-md-8">
                                                <label class="fileUpload btn btn-primary file-upload-btn-primary" for="docFileToUpload" ng-disabled="uploadExcelCtrl.disableSubmit">
                                                    <span>Select File</span>
                                                    <input name="docFileToUpload" ng-disabled="uploadExcelCtrl.disableSubmit" class="upload" type="file" id="docFileToUpload" ng-model="uploadExcelCtrl.repositoryFile" base-sixty-four-input accept=".xlsx" />
                                                </label>
                                                <span>{{uploadExcelCtrl.repositoryFile.filename}}</span>
                                            </div>
                                        </div>
                                    </div>   
                                </div>
                                <div class="modal-footer"> 
                                    <button class="btn btn-primary" type="button"  ng-disabled="!uploadExcelCtrl.repositoryFile || uploadExcelCtrl.disableSubmit" ng-click="uploadExcelCtrl.upload()">Submit</button>
                                    <button class="btn btn-info" type="button" ng-disabled="uploadExcelCtrl.disableSubmit" ng-click="uploadExcelCtrl.cancel()">Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    `,
                controller: class uploadExcelCtrl {

                    messages: interfaces.applicationcore.IMessage[];
                    disableSubmit: boolean = false;
                    repositoryFile: interfaces.applicationcore.IRepositoryFile;

                    filterExcelData: interfaces.master.IFilterExcelData;

                    constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                        private countryService: interfaces.applicationcore.ICountryService,
                        private entityService: interfaces.applicationcore.IEntityService,
                        private requirementGroupService: interfaces.master.IRequirementGroupService,
                        public generalService: interfaces.applicationcore.IGeneralService,
                        private $rootScope: interfaces.applicationcore.IRootScope,
                        private $state: ng.ui.IStateService,
                        private refundClaimTypeService: interfaces.tariff.IRefundClaimTypeService,
                        public bsLoadingOverlayService,
                        private requirementGroupId : number
                    ) {
                      
                    }

             

                    cancel() {
                        this.$uibModalInstance.close(false);
                    }

                    upload() {
                        this.disableSubmit = true;
                        

                         this.filterExcelData = <interfaces.master.IFilterExcelData>{
                            requirementGroupId: this.requirementGroupId,
                            repositoryFile:this.repositoryFile
                         };

                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'excelupload'
                        },
                            () => {

                                this.requirementGroupService.uploadFilterListFromExcel().save(this.filterExcelData, (result) => {
                                    this.generalService.displayMessageHandler(result);
                                    if (!result.HasErrorMessage) {
                                        this.$uibModalInstance.close(true);
                                    }

                                    this.$uibModalInstance.close(true);
                                }, (errorResult) => {
                                    this.generalService.displayMessageHandler(errorResult.data);

                                    if (errorResult.data.Messages) {
                                        this.messages = [];
                                    }

                                    angular.forEach(errorResult.data.Messages, (o) => {
                                        angular.forEach(o.MessageItems, (n) => {
                                            this.messages.push(n);
                                        });
                                    });
                                    }).$promise;

                            });
                    }
                },
                controllerAs: "uploadExcelCtrl",
                resolve: { requirementGroupId : this.requirementGroupId
                }
            }).result.then((result) => {
                if (result) {
                    this.loadRequirementGroup();
                }
            });
        }

    }

    angular.module("app").controller("requirementGroupUpdateCtrl",controllers.master.requirementGroupUpdateCtrl);
  }
}
